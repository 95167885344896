import { createContext, useContext } from "react";

type TrackingContextType = {
  isTrackingInitialized: boolean;
};

export const TrackingContext = createContext<TrackingContextType | null>(null);

export const useTracking = () => {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error(
      "useTracking must be used within a TrackingContextProvider",
    );
  }

  return context;
};
